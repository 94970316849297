import moment from "moment";
import React, { Component } from "react";
import { IoMdCloudDownload } from "react-icons/io";
import LoadingPage from "../../components/Loading/Loading";
import Reports from "../Reports/Reports";
import Modal from "react-modal";
import Table from "../../components/Table/TableTickets";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import referenceLibrary from "../../store/referenceLibrary";
import utilityFunctions from "../../store/utilityFunctions";
import "../common.css";
import "./Warranties.css";

export default class Warranties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      warranties: [],
      statusFilter: "Open",
      orderBy: "created",
      order: "desc",
      pageSize: 25,
      pageNumber: 1,
      searchFilter: "",
      employeeFilter: 0,
      statusSelector: "0",
      totalRows: 0,
      showReport: false,
      nextUrl: "",
      prevUrl: "",
      serviceCenters: [],
      serviceCenterCentrals: [],
      serviceCenterFilter: "",
    };
    const userInfo = utilityFunctions.getCookie("userInfo");
    this.userRole = userInfo && JSON.parse(userInfo).user.role;
    this.user = userInfo && JSON.parse(userInfo).user;
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    const assignto = urlParams.get("assignto");
    const allocated = urlParams.get("allocated");
    const newtickets = urlParams.get("newtickets");
    const duedates = urlParams.get("duedates");

    if (assignto > "0") {
      if (assignto === "unassigned") {
        this.setState({ statusFilter: "Unassigned" });
        this.retrieveWarranties(this.props.selectedbrandID, "Unassigned", 1, null, null, null, "-created");
      } else this.retrieveWarranties(this.props.selectedbrandID, null, 1, null, assignto, null, "-created");
    } else if (status) {
      this.retrieveWarranties(this.props.selectedbrandID, null, 1, null, null, status, "-created");
    } else if (allocated) {
      if (allocated === "Not Allocated") {
        this.setState({ statusFilter: "Unassigned" });
        this.retrieveWarranties(this.props.selectedbrandID, "Unassigned", 1, null, null, null, "-created");
      } else if (allocated === "Allocated") {
        this.setState({ statusFilter: "Assigned" });
        this.retrieveWarranties(this.props.selectedbrandID, "open_and_assigned", 1, null, null, null, "-created");
      }
    } else if (newtickets) {
      if (newtickets === "today") {
        this.retrieveWarranties(this.props.selectedbrandID, null, null, null, null, null, null, `&created__gte=${moment().format("YYYY-MM-DD")}`);
      } else if (newtickets === "previous") {
        this.retrieveWarranties(
          this.props.selectedbrandID,
          null,
          null,
          null,
          null,
          null,
          null,
          `&created__gte= ${moment().subtract(1, "days").format("YYYY-MM-DD")}
          &created__lt=${moment().format("YYYY-MM-DD")}`
        );
      }
    } else if (duedates) {
      if (duedates === "Today") {
        this.setState({ statusFilter: "Open" });
        this.retrieveWarranties(this.props.selectedbrandID, "Open", null, null, null, null, null, null, `&due_date=${moment().format("YYYY-MM-DD")}`);
      } else if (duedates === "Tomorrow") {
        this.setState({ statusFilter: "Open" });
        this.retrieveWarranties(
          this.props.selectedbrandID,
          "Open",
          null,
          null,
          null,
          null,
          null,
          null,
          `&due_date=${moment().add(1, "days").format("YYYY-MM-DD")}`
        );
      } else if (duedates === "Overdue") {
        this.setState({ statusFilter: "Open" });
        this.retrieveWarranties(
          this.props.selectedbrandID,
          "Open",
          null,
          null,
          null,
          null,
          null,
          null,
          `&due_date__lt=${moment().format("YYYY-MM-DD")}`
        );
      } else if (duedates === "2 Days") {
        this.setState({ statusFilter: "Open" });
        this.retrieveWarranties(
          this.props.selectedbrandID,
          "Open",
          null,
          null,
          null,
          null,
          null,
          null,
          `&due_date=${moment().add(2, "days").format("YYYY-MM-DD")}`
        );
      } else if (duedates === ">2 Days") {
        this.setState({ statusFilter: "Open" });
        this.retrieveWarranties(
          this.props.selectedbrandID,
          "Open",
          null,
          null,
          null,
          null,
          null,
          null,
          `&due_date__gt=${moment().add(2, "days").format("YYYY-MM-DD")}`
        );
      } else if (duedates === "No Due Date") {
        this.setState({ statusFilter: "Open" });
        this.retrieveWarranties(this.props.selectedbrandID, "Open", null, null, null, null, null, null, `&no_due_date=true`);
      }
    } else this.retrieveWarranties(this.props.selectedbrandID);
    this.retrieveEmployees();
    this.retrieveServiceCenters();
    this.retrieveServiceCenterCentrals();
  }
  componentDidUpdate(prevProps) {
    if (this.props.selectedbrandID !== prevProps.selectedbrandID) {
      this.retrieveWarranties(this.props.selectedbrandID, "open");
    }
  }

  retrieveEmployees = () => {
    this.props
      .protectedFetch(config.backendServer + "employee/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const sortEmp = res.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        this.setState({ employee: sortEmp });
      })
      .catch(() => {
        console.log("fail");
      });
  };
  retrieveServiceCenterCentrals = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "service_center_centrals/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        //do not show email with @treeclouds.com and @karadigital.co
        const optionServiceCenters = res?.map((s) => {
          return { value: s.id, label: s.name };
        });
        let optionServiceCenterHeader = [];
        res?.forEach((s) => {
          s.service_centers.forEach((c) => {
            optionServiceCenterHeader.push({ value: c.id, label: s.name + " - " + c.name });
          });
        });

        this.setState({
          loading: false,
          serviceCenterCentrals: res,
          optionServiceCenterHeader: optionServiceCenterHeader,
          // serviceCenters: serviceCenters,
          optionServiceCenterCentrals: [...[{ value: 0, label: "Select.." }], ...optionServiceCenters],
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  retrieveServiceCenters = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "service_centers/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        //do not show email with @treeclouds.com and @karadigital.co
        this.setState({ loading: false, serviceCenters: res });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  retrieveWarranties = (id, criteriaFilter, pageNumber, pageRowSize, assignedTo, status, orderingTxt, newtickets, duedates, svcCenter) => {
    const statusFilter = newtickets ? "" : criteriaFilter || this.state.statusFilter;
    this.setState({ loading: true });
    const queryFilter = this.state.searchFilter?.length > 0 && !criteriaFilter ? `&q=${this.state.searchFilter}` : "";
    const fllterAssignTo = assignedTo ? `&assignedTo=${assignedTo}` : "";
    const fllterServiceCenter = svcCenter ? `&service_center=${svcCenter}` : "";
    const filterStatus = status ? `&status=${status}` : "";
    const filterCriteria = queryFilter?.length === 0 ? `&filter_criteria=${statusFilter?.toLowerCase()}` : "";
    const newTickets = newtickets ? newtickets : "";
    const dueDates = duedates ? duedates : "";

    // const ordering = "&ordering=";
    const ordering = orderingTxt
      ? `&ordering=${orderingTxt}`
      : this.state.orderBy?.length > 0
      ? `&ordering=${this.state.order === "asc" ? this.state.orderBy : "-" + this.state.orderBy}`
      : "";
    const pageSize = `&page_size=${pageRowSize || this.state.pageSize}`;
    const pageNo = `&page=${pageNumber || this.state.pageNumber}`;

    const url =
      config.backendServer +
      `tickets/?brandId=${id}${filterCriteria}${fllterAssignTo}${queryFilter}${ordering}${pageSize}${pageNo}${filterStatus}${newTickets}${dueDates}${fllterServiceCenter}`;
    this.props
      .protectedFetch(url, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          loading: false,
          warranties: res.results,
          totalRows: res.count,
          nextUrl: res.next,
          prevUrl: res.previous,
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  filterChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      this.setState({
        statusFilter: "",
        employeeFilter: "",
        statusSelector: "",
      });
      this.retrieveWarranties(this.props.selectedbrandID);
    }
  };
  handleBtnSearch = () => {
    this.setState({ statusFilter: "", employeeFilter: "", statusSelector: "" });
    this.retrieveWarranties(this.props.selectedbrandID);
  };
  handleFilterStatus = (val) => {
    const statusText = referenceLibrary.statusSelectors.find((x) => x.id === parseInt(val))?.name || "";
    if (statusText === "") {
      this.setState({ statusSelector: "0" });
      this.retrieveWarranties(this.props.selectedbrandID, null, 1, null, this.state.employeeFilter, null, "-created");
    } else {
      this.setState({ statusSelector: val });
      this.retrieveWarranties(this.props.selectedbrandID, null, 1, null, this.state.employeeFilter, statusText, "-created");
    }
  };

  handleFilterAssignTo = (employeeID) => {
    this.setState({
      employeeFilter: employeeID,
      pageNumber: 1,
    });
    const statusText = referenceLibrary.statusSelectors.find((x) => x.id === parseInt(this.state.statusSelector))?.name || "";
    if (employeeID > "0") {
      this.retrieveWarranties(this.props.selectedbrandID, null, 1, null, employeeID, statusText, "-created");
    } else {
      this.setState({
        employeeFilter: "",
      });
      this.retrieveWarranties(this.props.selectedbrandID, null, 1, null, null, statusText, "-created");
    }
  };
  handleFilterServiceCenter = (serviceCenterId) => {
    this.setState({
      serviceCenterFilter: serviceCenterId,
      pageNumber: 1,
    });
    const statusText = referenceLibrary.statusSelectors.find((x) => x.id === parseInt(this.state.statusSelector))?.name || "";
    if (serviceCenterId > "0") {
      this.retrieveWarranties(this.props.selectedbrandID, null, 1, null, null, statusText, "-created", null, null, serviceCenterId);
    } else {
      this.setState({
        serviceCenterFilter: "",
      });
      this.retrieveWarranties(this.props.selectedbrandID, null, 1, null, null, statusText, "-created");
    }
  };

  statusFilterChange = (value) => {
    this.setState({
      statusFilter: value,
      searchFilter: "",
      employeeFilter: "",
      statusSelector: "",
      pageNumber: 1,
      order: "desc",
      orderBy: "created",
    });
    this.retrieveWarranties(this.props.selectedbrandID, value, 1, null, null, null, "-created");
  };

  formatValueWithCommas = (value) => {
    if (value === "") return value;

    // Split the value into integer and decimal parts (if applicable)
    const parts = value.toString().split(".");
    let formattedValue = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts.length > 1) {
      const decimalPart = parts[1].slice(0, 2);
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue;
  };

  // Service Complete
  // Not Approve
  // Did not Proceed
  getColorByTicketCombination = (ticketType, typeOfService) => {
    const combinationKey = `${ticketType}_${typeOfService}`;

    const colorMap = {
      warranty_service: "#FFD700", // Example color for warranty service
      warranty_repair: "#ADD8E6", // Example color for warranty repair
      out_of_warranty_service: "#90EE90", // Example color for out of warranty service
      out_of_warranty_repair: "#FFB6C1", // Example color for out of warranty repair
      // Add more combinations as needed
    };

    return colorMap[combinationKey] || "transparent"; // Fallback to 'transparent' if no match
  };
  render() {
    // if (this.state.loading) {
    //   return <LoadingPage />;
    // }
    let warranties = this.state.warranties;

    // Filter out warranties
    let filteredWarranties = [];

    warranties.map((val) => filteredWarranties.push(val));
    const tableHead = [
      {
        id: "ticket_number",
        filter: "ticket_id",
        label: "ID",

        valueDisplay: (value) => value,
      },
      {
        id: "date_received",
        filter: "created",
        label: "Received Date",

        defaultSort: "desc",
        valueDisplay: (value) => moment(value).format("DD-MM-YYYY"),
      },
      {
        id: "last_activity_date",
        filter: "last_activity_date",
        label: "Last Activity Date",

        valueDisplay: (value) => moment(value).format("DD-MM-YYYY"),
      },
      {
        id: "ticket_type",
        filter: "ticket_type",
        label: "Ticket Type",

        valueDisplay: (value) => value || "(missing)",
      },
      {
        id: "device_name",
        filter: "user_device__device__name",
        label: "Device Name",
        width: "200px",

        valueDisplay: (value) => value || "(missing)",
      },
      {
        id: "owner_name",
        filter: "user_device__customer__first_name",
        label: "Owner Name",
        width: "180px",
        valueDisplay: (value) => value || "(missing)",
      },
      {
        id: "refund_amount",
        filter: "refund_amount",
        label: "Refund Amount",
        width: "70px",
        valueDisplay: (value) => `$ ${this.formatValueWithCommas(value)}`,
      },

      {
        id: "status",
        filter: "status",
        label: "Status",
        width: "100px",
        valueDisplay: (value) => value || "(missing)",
      },

      {
        id: "service_center",
        filter: "service_center",
        label: "Service Center",
        width: "100px",
        valueDisplay: (value) => value,
      },
      {
        id: "assigned_to",
        filter: "assigned_to__employee__first_name",
        label: "Assigned To",
        valueDisplay: (value) => value,
      },
      {
        id: "due_date",
        filter: "due_date",
        label: "Due Date",
        valueDisplay: (value) => value,
      },
    ];

    return (
      <div className={"page"}>
        <div className={"pageHeader d-flex justify-content-between align-items-center mb-2"}>
          <div className="border-bottom ml-2">
            <h5>Tickets</h5>
          </div>
          {this.userRole === "Admin" && (
            <button className="k-btn k-btn-primary k-btn-sm" onClick={() => this.setState({ showReport: true })}>
              <IoMdCloudDownload
                // style={{ color: "red", cursor: "pointer", marginLeft: "5px" }}
                title="Export Report to Excel"
                onClick={() => this.setState({ showReport: true })}
              />{" "}
              Export Report
            </button>
          )}
        </div>

        <div className="row border-1 rounded" style={{ border: "1px solid lightgray", margin: "1px" }}>
          <div className={"p-3 col-md-7 mr-auto "}>
            <>
              <div className="form-check form-check-inline">
                <input
                  style={{ marginTop: "5px" }}
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="all"
                  value={this.state.statusFilter}
                  checked={this.state.statusFilter === "All"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.statusFilterChange("All");
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="all">
                  All
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  style={{ marginTop: "5px" }}
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="open"
                  value="Open"
                  checked={this.state.statusFilter === "Open"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.statusFilterChange("Open");
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="open">
                  Open
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  style={{ marginTop: "5px" }}
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="assigned"
                  value="Assigned"
                  checked={this.state.statusFilter === "Assigned"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.statusFilterChange("Assigned");
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="assigned">
                  Assigned
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  style={{ marginTop: "5px" }}
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="unassigned"
                  value="Unassigned"
                  checked={this.state.statusFilter === "Unassigned"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.statusFilterChange("Unassigned");
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="unassigned">
                  Unassigned
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  style={{ marginTop: "5px" }}
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="myticket"
                  value="MyTicket"
                  checked={this.state.statusFilter === "My_Ticket"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.statusFilterChange("My_Ticket");
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="myticket">
                  My tickets
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  style={{ marginTop: "5px" }}
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="archived"
                  value="Archived"
                  checked={this.state.statusFilter === "Archived"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.statusFilterChange("Archived");
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="archived">
                  Archived
                </label>
              </div>
            </>
          </div>

          <div className={"col-md-5 pt-2"} style={{ paddingLeft: "10vw" }}>
            <div className="input-group  mb-3 ">
              <input
                type="text"
                placeholder="Search tickets"
                id={"searchFilter"}
                className={"form-control"}
                onChange={this.filterChange}
                onKeyPress={this.handleKeyPressSearch}
                // onInput={()=> alert("submit")}
                value={this.state.searchFilter}
                width={"70px"}
              />
              <div className="input-group-prepend">
                <span className="form-control " onClick={this.handleBtnSearch}>
                  <i className="fa fa-search form-control-icon"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={"pageContent clearfix"}>
          {this.state.loading && <LoadingPage />}
          <div className={"admin-table-container card"}>
            <Table
              head={tableHead}
              data={filteredWarranties}
              filterServiceCenter={(val) => this.handleFilterServiceCenter(val)}
              serviceCenters={this.state.serviceCenters}
              serviceCenterCentrals={this.state.serviceCenterCentrals}
              serviceCenter={this.state.serviceCenterFilter}
              optionServiceCenterCentrals={this.state.optionServiceCenterCentrals}
              optionServiceCenterHeader={this.state.optionServiceCenterHeader}
              employee={this.state.employee}
              reloadMini={() => this.retrieveWarranties(this.props.selectedbrandID)}
              protectedFetch={this.props.protectedFetch}
              selectedbrandID={this.props.selectedbrandID}
              role={this.userRole}
              statusSelectors={referenceLibrary.statusSelectors}
              statusSelector={this.state.statusSelector}
              changeStatusSelector={(val) => this.setState({ statusSelector: val })}
              onOrder={(val) =>
                this.setState({
                  order: val,
                })
              }
              onOrderBy={(orderBy_) => this.setState({ orderBy: orderBy_ })}
              order={this.state.order}
              orderBy={this.state.orderBy}
              filteredStatus={(val) => this.handleFilterStatus(val)}
              statusFilter={this.state.statusFilter}
              filterAssignTo={(val) => this.handleFilterAssignTo(val)}
              assignedTo={this.state.employeeFilter}
              totalRows={this.state.totalRows}
              rowsPerPage={this.state.pageSize}
              rowsPerPageChg={(val) => this.setState({ pageSize: val })}
              pageNumber={this.state.pageNumber}
              pageNumberChg={(val) => this.setState({ pageNumber: val })}
              retrieveWarranty={(pageNumber, pageRowSize, assignTo, status, ordering) =>
                this.retrieveWarranties(this.props.selectedbrandID, this.state.statusFilter, pageNumber, pageRowSize, assignTo, status, ordering)
              }
            />
          </div>
        </div>
        <Modal isOpen={this.state.showReport} contentLabel={"Export Report"} className={"input-modal-date"}>
          <Reports protectedFetch={this.props.protectedFetch} handleCloseModal={() => this.setState({ showReport: false })} />
        </Modal>
      </div>
    );
  }
}

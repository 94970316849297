import React, { Component } from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import axios from "axios";
import config from "../../../config";
import Select from "react-select";
import utilityFunctions from "../../../store/utilityFunctions";
export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddUser: false,
      employeeID: "",
      selectedUsers: this.props.data,
      selectedUser: null,
      showConfirmDelete: false,
    };
  }

  handleDropDownAssignTo = (e) => {
    this.setState({ employeeID: e.target.value });
  };

  handleClickDelete = (item) => {
    this.setState({ selectedUser: item, showConfirmDelete: true });
  };
  handleDelete = () => {
    let url = `${config.backendServer}service_center/staff/${this.state.selectedUser?.id}/`;
    const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    fetch(url, options).then((response) => {
      console.log(response.status);
      if (response.status === 204) {
        let tempUsers = this.state.selectedUsers.filter((x) => x.id !== this.state.selectedUser?.id);
        this.setState({ showConfirmDelete: false, loading: false, selectedUsers: tempUsers });
        // this.props.retrieveServiceCenter();
      } else if (response.status === 406) {
        alert(`Delete is not allowed, User Service Center is already linked to ticket`);
      } else if (response.status === 409) {
        alert(`User Service does not exist.`);
      } else {
        alert("Problem with system. Please refresh and try again later.");
      }
    });

    this.props.setDefaultTab();
  };
  handleAddUserDeviceCenter = () => {
    this.setState({ loading: true });
    if (!this.state.employeeID) {
      alert("User is not selected");
      return;
    }
    let payload = {
      service_center: this.props.serviceCenterId,
      employee: this.state.employeeID,
    };
    const userInfo = localStorage?.getItem("userInfo") || utilityFunctions?.getCookie("userInfo");
    const token = (userInfo && JSON?.parse(userInfo)?.access_token) || "";
    let options = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .post(`${config.backendServer}service_center/${this.props.serviceCenterId}/staff/`, payload, options)
      .then((res) => {
        console.log(res);

        let tempUsers = [...this.state.selectedUsers];
        tempUsers.push(res.data);
        this.setState({ loading: false, showAddUser: false, selectedUsers: tempUsers });
        // this.props.retrieveServiceCenter();
        this.props.setDefaultTab();

        // console.log("service user");
        // this.props.setCurrentTab;
        //   resolve(res);
      })
      .catch(() => {
        this.setState({ loading: false });
        console.log("service user failed");
        //   resolve(err);
      });
  };

  render() {
    const selectedUsersTemp = this.props.data.map((x) => {
      return x.employee;
    });
    const optionEmployees = this.props.employees
      ?.filter((x) => !selectedUsersTemp.includes(x.id))
      .map((val) => {
        return { value: parseInt(val.id), label: `${val.name} - ${val.user_role}` };
      });
    const selectedAssignTo = optionEmployees.find((x) => x.value === this.state.employeeID);
    return (
      <div>
        <div style={{ margin: "10px" }}>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.setState({ showAddUser: true })}>
            Add Staff
          </button>
        </div>
        <ul className="list-group">
          <li className="list-group-item bg-light">
            <b>Staff Name</b>
          </li>
          {this.state.selectedUsers &&
            this.state.selectedUsers
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((item, i) => {
                return (
                  <li key={i} className="list-group-item">
                    <div className="row">
                      <div className="col-10">{`${i + 1}.  ${item?.name}`}</div>
                      <div
                        className="col btn btn-outline-danger btn-sm"
                        onClick={() => {
                          this.handleClickDelete(item);
                        }}
                      >
                        {" "}
                        Delete
                      </div>
                    </div>
                  </li>
                );
              })}
        </ul>
        <Modal
          isOpen={this.state.showAddUser}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"input-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showAddUser: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">{this.state.editedNote ? "Edit" : "Add"} Staff</div>
            <form>
              <div className={"card-body"}>
                <Select
                  data-testid="assign-to-select"
                  defaultValue={selectedAssignTo}
                  isSearchable={true}
                  options={optionEmployees}
                  // onInputChange={(val) => this.handleSearchServiceCenter(val)}
                  onChange={(obj) => {
                    this.setState({ employeeID: obj.value });
                    // this.handleSearchServiceCenter(obj);
                  }}
                />
              </div>
              <div className={"card-footer"}>
                <button type="button" className={"btn btn-outline-primary mr-3"} onClick={this.handleAddUserDeviceCenter}>
                  Save
                </button>
                <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showAddUser: false, addedNote: "" })}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal isOpen={this.state.showConfirmDelete} contentLabel={"Reset Password"} className={"input-modal-small"}>
          <div className="card">
            <button onClick={() => this.setState({ showConfirmDelete: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">
              <h6>Delete Confirmation</h6>
            </div>
            <form>
              <div className="card-body text-center">
                <div className="mb-3">Delete user {this.state.selectedUser?.name}?</div>
              </div>
              <div className="card-footer">
                <div className="row ">
                  <div className="col-6 text-right ">
                    <button
                      type="button"
                      className={"btn btn-outline-danger"}
                      onClick={() => {
                        this.handleDelete();
                        // if (this.state.modalCaption.toLowerCase().includes("reset")) this.handleResetPassword();
                        // else this.handleSendVerification(event);
                        // this.setState({ showModalResetPassword: false });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="col-6 text-left">
                    <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showConfirmDelete: false })}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import config from "../../../../../config";
import errorFunctions from "../../../../../store/errorFunctions";
import Select from "react-select";

export default class DiagnosticRepair extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      actionIndex: 0,
      summary_of_issue: this.props.reportContent?.summary_of_issue || "",
      received_items: this.props.reportContent?.received_items || "",
      technician: this.props.reportContent?.technician || 0,
    };
  }
  handleSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    console.log(this.state.addComment);

    const payload = {
      // ticketId: this.props.ticketId,
      summary_of_issue: this.state.summary_of_issue,
      received_items: this.state.received_items,
      technician: this.state.technician,
    };

    const url =
      this.props.reportMode === "new"
        ? `${config.backendServer}ticket/${this.props.ticketId}/diagnostic-reports/new/`
        : `${config.backendServer}ticket/diagnostic-reports/${this.props.reportContent.id}/`;

    this.props
      .protectedFetch(url, this.props.reportMode === "new" ? "POST" : "PUT", payload, errorFunctions.checkResponseSent)
      .then(() => {
        this.setState({
          summary_of_issue: "",
          received_items: "",
          technician: 0,
          loading: false,
        });

        this.props.retrieveWarranty();
        this.props.setDefaultTab();
        // this.retrieveWarranty(dataToSend.id);
        // this.props.closeModal();
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
        this.props.closeModal();
      });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  handleDropDown = (e) => {
    // let technician = e.target.value;
    this.setState({ technician: e.value });
  };

  render() {
    const technicianOption = this.props.employeeList?.map((val) => {
      return { value: val.id, label: val.name };
    });

    const selectedTechnician = technicianOption.find((x) => x.value === this.state.technician);
    return (
      <div className="card">
        <form onSubmit={this.handleSubmit}>
          <div className="card-header">
            <h5>Diagnostic Report</h5>
          </div>
          <div className="card-body" style={{ width: "800px", height: "60vh", overflow: "auto" }}>
            <div className="mb-3 border rounded p-3">
              <div>
                <b>Issue</b>
              </div>
              <div>Summary Issue</div>
              <div>
                <textarea
                  id="summary_of_issue"
                  disabled={this.props.reportMode === "view"}
                  className="form-control"
                  rows={4}
                  style={{ width: "100%" }}
                  onChange={this.handleInputChange}
                  value={this.state.summary_of_issue}
                />
              </div>
              <div>Received Item</div>
              <div>
                <textarea
                  id="received_items"
                  className="form-control"
                  disabled={this.props.reportMode === "view"}
                  value={this.state.received_items}
                  rows={4}
                  style={{ width: "100%" }}
                  onChange={this.reportMode !== "view" && this.handleInputChange}
                />
              </div>
            </div>

            <div className="mb-3 border rounded p-3">
              <b>Worked Performed</b>
              <div className={"single-warranty-details-item mb-3"}>
                <span>Technician:</span>
                <Select
                  styles={{ zIndex: 999 }}
                  data-testid="assign-to-select"
                  value={selectedTechnician}
                  isSearchable={true}
                  options={technicianOption}
                  onChange={(obj) => {
                    this.handleDropDown(obj);
                  }}
                  isDisabled={this.props.reportMode === "view"}
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button
              type="submit"
              disabled={this.props.reportMode === "view"}
              // onClick={() => this.props.closeModal()}
              className="btn btn-outline-primary m-2"
            >
              Save
            </button>

            <button onClick={() => this.props.closeModal()} className="btn btn-outline-secondary m-2">
              Close
            </button>
          </div>
        </form>
      </div>
    );
  }
}

import React, { Component } from "react";
import "./SingleWarranty.css";
import "../../common.css";
import config from "../../../config";
import LoadingPage from "../../../components/Loading/Loading";
import Tabs from "../../../components/Tabs/Tabs";
import errorFunctions from "../../../store/errorFunctions";
import Modal from "react-modal";
// import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./SingleWarranty.css";
import Repair from "./Repair/Repair";
import CustomerInfo from "./CustomerInfo/CustomerInfo";
import DeviceDetails from "./DeviceDetails/DeviceDetails";
import Activities from "./Activities/Activities";
import TicketProcess from "./TicketProcess/TicketProcess";
Modal.setAppElement("body");

const inWarrantySteps = {
  Approved: {
    next: "Under review",
    buttonText: "Mark as received",
    modalText: "Confirm that you have received the item.",
  },
  "In transit": {
    next: "Under review",
    buttonText: "Mark as received",
    modalText: "Confirm that you have received the item.",
  },
  "Under review": {
    next: "Under servicing",
    buttonText: "Review complete - mark as under service",
    modalText: "Confirm that item is ready to be serviced.",
  },
  "Under servicing": {
    next: "Complete",
    buttonText: "Mark as completed",
    modalText: "Confirm that item is ready to be sent to customer.",
  },
  Complete: {},
};
const outOfWarrantySteps = {
  Approved: {
    next: "Under review",
    buttonText: "Mark as received",
    modalText: "Confirm that you have received the item.",
  },
  "In transit": {
    next: "Under review",
    buttonText: "Mark as received",
    modalText: "Confirm that you have received the item.",
  },
  "Under review": {},
  "Approve invoice": {
    next: "Under servicing",
    buttonText: "Payment received - mark as under service",
    modalText: "Confirm that item is ready to be serviced.",
  },
  "Awaiting payment": {
    next: "Under servicing",
    buttonText: "Payment received - mark as under service",
    modalText: "Confirm that item is ready to be serviced.",
  },
  "Under servicing": {
    next: "Complete",
    buttonText: "Mark as completed",
    modalText: "Confirm that item is ready to be sent to customer.",
  },
  Complete: {},
};

export default class SingleWarranty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      details: null,
      // userDevice: null,
      redirect: false,
      employee: null,
      statusTicket: "",
      statusTicketType: "",
      typeService: "",
      reportCount: 0,
      defaultTab: null,
      employees: [],
      serviceCenterLocations: [],
      serviceCenterCentrals: [],
      optionServiceCenterLocations: null,
      optionServiceCenterCentrals: null,
      optionEmployees: [],
    };
  }
  ticketId = this.props.computedMatch?.params.id;
  componentDidMount() {
    this.retrieveEmployeeList();
    this.retrieveWarranty(this.ticketId);

    // this.retrieveServiceCenterCentral();
  }
  retrieveServiceCenterCentral = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "service_center_centrals/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        //do not show email with @treeclouds.com and @karadigital.co
        const optionServiceCenters = res?.map((s) => {
          return { value: s.id, label: s.name };
        });
        // let serviceCenters = [];
        // res.forEach((x) => {
        //   const sc = x.service_centers;
        //   serviceCenters = [...serviceCenters, ...sc];
        // });
        // const serviceCenters = res.foreA((s) => {
        //   return s.service_centers;
        // });

        this.setState({
          loading: false,
          serviceCenterCentrals: res,
          // serviceCenters: serviceCenters,
          optionServiceCenterCentrals: [...[{ value: 0, label: "Select.." }], ...optionServiceCenters],
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };
  retrieveServiceCenterLocation = (id) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "service_center_central/" + id, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        //do not show email with @treeclouds.com and @karadigital.co
        const optionServiceCenterLocations = res?.service_centers?.map((s) => {
          return { value: s.id, label: s.name };
        });
        // let serviceCenters = [];
        // res.forEach((x) => {
        //   const sc = x.service_centers;
        //   serviceCenters = [...serviceCenters, ...sc];
        // });
        // const serviceCenters = res.foreA((s) => {
        //   return s.service_centers;
        // });
        // this.retrieveServiceCenterLocation(res[0].id)
        this.setState({
          loading: false,
          serviceCenterLocations: res,
          // serviceCenters: serviceCenters,
          optionServiceCenterLocations: [...[{ value: 0, label: "Select.." }], ...optionServiceCenterLocations],
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };
  retrieveWarranty = async (id) => {
    this.setState({ loading: true });
    await this.props
      .protectedFetch(config.backendServer + "ticket/" + id + "/", "GET", { id }, errorFunctions.checkResponseThenJson)
      .then((res) => {
        let count = -1;
        if (res.diagnostic_report.length > 0) count = 0;
        if (res.repair_report.length > 0) count = 1;
        if (res.quality_check_report.length > 0) count = 2;
        // console.log(res);this.st
        if (this.state.serviceCenterCentrals.length === 0) {
          this.retrieveServiceCenterCentral();
        }

        if (res.service_center_central) this.retrieveServiceCenterLocation(res.service_center_central);

        this.setState({
          loading: false,
          details: res,
          // activities: res.ticket_activities,
          // statusTicketType: res.ticket_type,
          // typeService: res.type_of_service,
          // userDevice: res.user_device,
          reportCount: count,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        console.log("fail");
      });
  };
  retrieveEmployeeList = () => {
    this.props
      .protectedFetch(config.backendServer + "employee/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        console.log(res);
        const sortEmp = res.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        this.setState({ employee: sortEmp });
        this.setState({
          employees: sortEmp,
          // optionEmployees: optionEmployees
        });
      })
      .catch(() => {
        console.log("fail");
      });
  };

  render() {
    // let that = this;
    if (this.state.loading) {
      return <LoadingPage />;
    }

    // const details = this.state.details || {};
    const warrantySteps = this.state.details?.out_of_warranty ? outOfWarrantySteps : inWarrantySteps;
    const optionEmployees = this.state.serviceCenters
      ?.find((x) => x.id === this.state.details?.service_center)
      ?.staff?.map((s) => {
        return { value: s.employee, label: s.name };
      });

    return (
      <div className={"page"}>
        {!this.state.details && (
          <div className={"page"}>
            <h2 className={"pageHeader"}>Error - page not found</h2>
          </div>
        )}
        <div className="row">
          <div className="col-md-4">
            <TicketProcess
              details={this.state.details}
              warrantySteps={warrantySteps}
              userInfo={this.props.userInfo}
              employees={this.state.employees}
              optionEmployees={optionEmployees}
              serviceCenterLocations={this.state.serviceCenterLocations}
              serviceCenterCentrals={this.state.serviceCenterCentrals}
              optionServiceCenterCentrals={this.state.optionServiceCenterCentrals}
              optionServiceCenterLocations={this.state.optionServiceCenterLocations}
              protectedFetch={this.props.protectedFetch}
              retrieveServiceCenter={() => this.retrieveServiceCenterCentral()}
              // retrieveServiceCenterLocation={(id) => this.retrieveServiceCenterLocation(id)}
              retrieveWarranty={() => this.retrieveWarranty(this.ticketId)}
            />
            <CustomerInfo details={this.state.details} />
            <DeviceDetails details={this.state.details} protectedFetch={this.props.protectedFetch} />
          </div>
          <div className="col-md-8">
            <Tabs defaultTab={this.state.defaultTab} resetTab={() => this.setState({ defaultTab: undefined })}>
              <div label="Activity">
                <Activities
                  userInfo={this.props.userInfo}
                  details={this.state.details}
                  protectedFetch={this.props.protectedFetch}
                  retrieveWarranty={() => this.retrieveWarranty(this.ticketId)}
                />
              </div>
              <div label="Repair Details">
                <Repair
                  device={this.state.details.user_device.device}
                  reportCount={this.state.reportCount}
                  protectedFetch={this.props.protectedFetch}
                  ticketId={this.ticketId}
                  ticketDetails={this.state.details}
                  employeeList={this.state.employees?.filter((e) => e?.user_role === "Service")}
                  retrieveWarranty={() => this.retrieveWarranty(this.ticketId)}
                  selectedbrandID={this.props.selectedbrandID}
                  setDefaultTab={() => this.setState({ defaultTab: "Repair Details" })}
                />
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

import moment from "moment";
import React, { Component } from "react";
import LoadingPage from "../../components/Loading/Loading";
import Table from "../../components/Table/TableCustomer";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import "../common.css";
import "./CustomerList.css";
// import { IoMdAddCircle } from "react-icons/io";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import CustomerAdd from "./CustomerAdd/CustomerAdd";

export default class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      customers: [],
      objCustomer: null,
      searchFilter: "",
      pageNumber: 1,
      rowsPerPage: 10,
      order: "asc",
    };
  }

  componentDidMount() {
    this.retriveCustomer();
  }

  retriveCustomer = (urlTxt, rowPerPage, pageNumber, searchTxt, orderTxt) => {
    let apiUrl = config.backendServer + `customers/?brandId=${this.props.selectedbrandID}` || urlTxt;
    if (urlTxt) apiUrl = urlTxt;
    else {
      const pageSize = `&page_size=${rowPerPage || this.state.rowsPerPage || ""}`;
      const pageNo = `&page=${pageNumber || this.state.pageNumber}`;
      const search = `&q=${searchTxt || this.state.search || ""}`;
      const ordering = `&ordering=${orderTxt || this.state.orderingBy || ""}`;

      apiUrl = apiUrl + `${pageSize}${pageNo}${search}${ordering}`;
    }

    this.setState({ loading: true });
    this.props
      .protectedFetch(apiUrl, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        //do not show email with @treeclouds.com and @karadigital.co
        let tempCustomers = [];
        if (res.results.length > 0) {
          tempCustomers = res.results.filter((x) => !x.email.includes("@treeclouds.com") && !x.email.includes("@karadigital.co"));
        }
        this.setState({ loading: false, customers: tempCustomers, objCustomer: res });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  filterChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  searchChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onKeyDown = (e) => {
    if (e.key === "Enter") this.retriveCustomer(null, null, null, this.state.searchFilter);
  };

  handleAddCustomer = () => {
    this.setState({ openCustomerAdd: true });
  };

  render() {
    const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
    if (this.state.loading) {
      return <LoadingPage />;
    }
    let customer = this.state.customers;

    // Filter out warranties
    let filterCustomer = customer;

    //RMAS-37 search filter function
    // if (this.state.searchFilter) {
    //   customer.forEach((item) => {
    //     if (
    //       item.first_name?.toLowerCase()?.includes(this.state.searchFilter.trim().toLowerCase()) ||
    //       item.last_name?.toLowerCase()?.includes(this.state.searchFilter.trim().toLowerCase()) ||
    //       item.email?.includes(this.state.searchFilter.trim().toLowerCase())
    //     ) {
    //       filterCustomer.push(item);
    //     }
    //   });
    // } else {
    //   customer.map((val) => filterCustomer.push(val));
    // }

    const tableHead = [
      {
        id: "first_name",
        label: "First Name",
        valueDisplay: (value) => value || "",
      },
      {
        id: "last_name",
        label: "Last Name",
        valueDisplay: (value) => value || "",
      },
      {
        id: "email",
        label: "Email",
        width: "200px",
        valueDisplay: (value) => value || "email empty",
      },
      {
        id: "mobile",
        label: "Phone Number",
        valueDisplay: (value) => value || "",
      },
      {
        id: "last_login",
        label: "Last Login",
        defaultSort: "desc",
        valueDisplay: (value) => (value === null ? "Never" : moment(value).format("DD-MM-YYYY HH:mm")),
      },
      {
        id: "verificationStatus",
        label: "Status",
        valueDisplay: (value) => value || "",
      },
      {
        id: "action",
        label: "Action",
        valueDisplay: (value) => value || "",
      },
    ];

    return (
      <div className={"page"}>
        <div className="row">
          <div className="d-flex justify-content-between mb-3">
            <div style={{ width: "120px", borderBottom: "1px solid black", textAlign: "center" }}>
              <h5>Customers</h5>
            </div>
            <button title="Add Customer" className="k-btn k-btn-primary k-btn-sm" onClick={this.handleAddCustomer}>
              Add New Customer
            </button>
          </div>
          <div className="d-flex justify-content-end">
            <div className="input-group col-3 pr-0">
              <input
                type="text"
                placeholder="Search Customer"
                id={"searchFilter"}
                className={"form-control"}
                onChange={this.searchChange}
                onKeyDown={this.onKeyDown}
                value={this.state.searchFilter}
                // width={"100rem"}
              />
              <div className="input-group-prepend">
                <span className="form-control ">
                  <i className="fa fa-search form-control-icon"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={"pageContent clearfix"}>
          {customer.length === 0 && <div>No customer</div>}
          {customer.length > 0 && (
            <div className={"admin-table-container card"}>
              <Table
                head={tableHead}
                data={filterCustomer}
                retriveCustomer={this.retriveCustomer}
                protectedFetch={this.props.protectedFetch}
                selectedbrandID={this.props.selectedbrandID}
                objCustomer={this.state.objCustomer}
                rowsPerPage={this.state.rowsPerPage}
                rowsPerPageChg={(val) => this.setState({ rowsPerPage: val })}
                pageNumber={this.state.pageNumber}
                onOrderBy={(orderBy_) => this.setState({ orderBy: orderBy_ })}
                pageNumberChg={(val) => this.setState({ pageNumber: val })}
                order={this.state.order}
                onOrder={(val) =>
                  this.setState({
                    order: val,
                  })
                }
              />
            </div>
          )}
        </div>
        <Modal
          isOpen={this.state.openCustomerAdd}
          // onRequestClose={() => this.setState({ openCustomerAdd: false })}
          contentLabel={"Add New Device."}
          // style={customStyles}
          className={isMobile ? "input-modal-mobi" : "input-modal"}
        >
          <button onClick={() => this.setState({ openCustomerAdd: false })} className={"info-modal-close"}>
            <MdClose />
          </button>

          <CustomerAdd
            protectedFetch={this.props.protectedFetch}
            handleCloseModal={() => this.setState({ openCustomerAdd: false })}
            handleRetriveCustomerList={() => this.retriveCustomer()}
            selectedBrandId={this.props.selectedbrandID}
          />
        </Modal>
      </div>
    );
  }
}

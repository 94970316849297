import React, { Component } from "react";
import Modal from "react-modal";
import "../../common.css";
import { MdClose } from "react-icons/md";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import utilityFunctions from "../../../store/utilityFunctions";
import axios from "axios";
// import Select from "react-select";
// import { postNewStaffFunction } from "./api";

Modal.setAppElement("body");

export default class CustomerEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,
      statusTicket: "",
      modalStatusConfirm: false,
      brands: [],
      roles: [],
      currentRole: "",
      id: "",
      email: this.props.customer.email,
      last_login: "",
      company: this.props.customer.company,
      first_name: this.props.customer.first_name,
      last_name: this.props.customer.last_name,
      address_line_one: this.props.customer.address_line_one,
      address_line_two: this.props.customer.address_line_two,
      suburb: this.props.customer.suburb,
      postcode: this.props.customer.postcode,
      stateVal: this.props.customer.state,
      suburbOptions: [],
      postcodeOptions: [],
      stateOptions: [],

      mobile: this.props.customer.mobile,
      brand: this.props.customer.brand,
      postError: "",
    };
  }

  componentDidMount() {
    this.retriveAllBrand();
    this.searchLocationState();
  }

  searchLocationState = () => {
    this.setState({ loading: true });
    utilityFunctions
      .protectedFetch(config.backendServer + "location/state/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const options = res.map((x) => {
          return { value: x.code, label: x.name };
        });
        this.setState({
          stateOptions: options,
        });
      })
      .catch(() => {
        // console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  searchLocationSuburb = (qState) => {
    this.setState({ loading: true });
    utilityFunctions
      .protectedFetch(config.backendServer + "location/suburb/?state=" + qState, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const options = res.results.map((x) => {
          return { value: x.name, label: x.name };
        });
        console.log(options);
        this.setState({
          suburbOptions: options,
          // suburb: options[0].value,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  searchLocationPostcode = (qSuburb) => {
    this.setState({ loading: true });
    utilityFunctions
      .protectedFetch(config.backendServer + "location/postcode/?suburb=" + qSuburb, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const options = res.results.map((x) => {
          return { value: x.code, label: x.code };
        });
        console.log(options);
        this.setState({
          postcodeOptions: options,
          // suburb: options[0].value,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  retriveAllBrand = () => {
    this.setState({ loading: true });
    utilityFunctions
      .protectedFetch(config.backendServer + "brands/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, brands: res });
      })
      .catch(() => {
        console.log("fail brand");
        this.setState({ loading: false });
      });
  };

  retriveAllRoles = () => {
    this.setState({ loading: true });
    utilityFunctions
      .protectedFetch(config.backendServer + "roles/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, roles: res });
      })
      .catch(() => {
        console.log("fail brand");
        this.setState({ loading: false });
      });
  };
  updateCustomer = (payload) => {
    const userInfo = localStorage?.getItem("userInfo") || utilityFunctions?.getCookie("userInfo");
    const token = (userInfo && JSON?.parse(userInfo)?.access_token) || "";
    let options = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return new Promise((resolve) => {
      axios
        .put(`${config.backendServer}customers/${this.props.customer.id}/`, payload, options)
        .then((res) => {
          resolve(res);
          this.props.retrieveCustomer();
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };

  handleSubmit = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();

    let payload = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      address_line_one: this.state.address_line_one,
      address_line_two: this.state.address_line_two,
      suburb: this.state.suburb,
      state: this.state.stateVal,
      mobile: this.state.mobile,
      postcode: this.state.postcode,
      brand: this.props.selectedBrandId,
    };

    const result = await this.updateCustomer(payload);
    if (result.status === 200 || result.status === 201) {
      this.setState({ loading: false });
      this.props.handleCloseModal();
      this.props.retrieveCustomer();
    } else {
      this.setState({
        loading: false,
        postError: JSON.stringify(result?.response?.data),
      });
    }
  };
  handleRole = (e) => {
    this.setState({ currentRole: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    return (
      <div className={"card"} style={{ height: "100%" }}>
        <button onClick={() => this.props.handleCloseModal()} className={"confirm-modal-close"}>
          <MdClose />
        </button>

        <h4 className={"card-header"}>Edit Customer</h4>
        <form onSubmit={this.handleSubmit}>
          <div className="card-body">
            <div className="row mb-3">
              <div className={"col"}>
                <label className={"single-warranty-details-label required"} htmlFor="first_name">
                  First name <span></span>
                </label>
                <input
                  type="text"
                  id="first_name"
                  required={true}
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.first_name}
                />
              </div>
              <div className={"col"}>
                <label className={"single-warranty-details-label required"} htmlFor="last_name">
                  Last name <span></span>
                </label>
                <input
                  type="text"
                  id="last_name"
                  required={true}
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.last_name}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className={"col"}>
                <label className={"single-warranty-details-label"}>Mobile</label>

                <input
                  type="tel"
                  id="mobile"
                  //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.mobile}
                />
              </div>
              <div className={"col"}>
                <label className={"single-warranty-details-label required"}>
                  Email<span></span>
                </label>

                <input type="email" id="email" required={true} className="form-control" onChange={this.handleChange} value={this.state.email} />
              </div>
            </div>

            <div className="row mb-3">
              <div className={"col"}>
                <label className={"single-warranty-details-label"}>Address Line 1:</label>
                <textarea
                  id="address_line_one"
                  required={false}
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.address_line_one}
                />
              </div>
              <div className={"col mb-3"}>
                <label className={"single-warranty-details-label "}>Address Line 2:</label>
                <textarea
                  id="address_line_two"
                  required={false}
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.address_line_two}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className={"col"}>
                <label className={"single-warranty-details-label"}>State</label>
                <input type="text" id="stateVal" required={true} className="form-control" onChange={this.handleChange} value={this.state.stateVal} />
                {/* <Select
                  required
                  options={this.state.stateOptions}
                  // onChange={() => alert("test")}
                  onChange={(obj) => {
                    this.setState({ stateVal: obj.value });
                    this.searchLocationSuburb(obj.value);
                  }}
                  // onInputChange={(val) => this.searchLocationState(val)}
                /> */}
              </div>
              <div className={"col"}>
                <label className={"single-warranty-details-label"}>Suburb</label>
                <input type="text" id="suburb" required={true} className="form-control" onChange={this.handleChange} value={this.state.suburb} />
                {/* <Select
                  required
                  options={this.state.suburbOptions}
                  onChange={(obj) => {
                    this.setState({ suburb: obj.value });
                    this.searchLocationPostcode(obj.value);
                  }}
                  // onInputChange={(val) => this.searchLocation("suburb", val, this.state.stateVal)}
                /> */}
              </div>
              <div className={"col"}>
                <label className={"single-warranty-details-label"}>Postcode</label>
                <input type="text" id="postcode" required={true} className="form-control" onChange={this.handleChange} value={this.state.postcode} />
                {/* <Select required options={this.state.postcodeOptions} onChange={(obj) => this.setState({ postcode: obj.value })} /> */}
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <span className={"mb-1 text-danger"}>{this.state.postError}</span>
              <div className="col-2">
                <button className={"btn btn-outline-primary"}>Update</button>
              </div>
              <div className="col-3">
                <button className="btn btn-outline-secondary" onClick={this.props.handleCloseModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
